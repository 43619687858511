import { faker as key } from "@faker-js/faker";
import React from "react";

function News({ stock }) {
  return (
    <div>
      <h1 className="font-bold text-xl">Sentiment based on this news</h1>
      {stock.news.length ? (
        stock.news.map((article) => (
          <a key={key.datatype.uuid()} href={article.link}>
            <div key={key.datatype.uuid()} className="bg-back mt-2 p-2 hover:text-primary">
              {article.header}
            </div>
          </a>
        ))
      ) : (
        <div>No articles found {">_<"}</div>
      )}
    </div>
  );
}

export default News;
