import React from "react";
import GaugeChart from "react-gauge-chart";

import { colorThemes } from "../../Theme";

const Sentiment = ({ stock }) => {
  return (
    <div className="grid gap-4 grid-flow-row w-96">
      <div>
        <h1 className="font-bold text-xl">Sentiment for the stock</h1>
        <p className="text-primary text-sm">(see how we calculate sentiment)</p>
      </div>
      <div>
        <GaugeChart
          id="gauge-chart"
          colors={[colorThemes.Original.textcolor, colorThemes.Original.primary]}
          animate={false}
          nrOfLevels={3}
          percent={stock.sentiment / 100}
          textColor="secondary"
        />
      </div>
    </div>
  );
};

export default Sentiment;
