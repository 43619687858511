import axios from "axios";

export const getStock = async (ticker) => {
  const baseURL = `http://localhost:8080/api/v1/stocks/${ticker}`;
 
  try {
    const {data} =  await axios.get(baseURL);
    return data;
  } catch (err) {
    console.log(err);
  }
};
