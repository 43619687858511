import axios from "axios";

export const getStockList = async () => {
  const baseURL = "http://localhost:8080/api/v1/stocks";
  try {
    const {data} =  await axios.get(baseURL);
    return data;
  } catch (err) {
    console.log(err);
  }
};
