import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedStock: null,
  wordEntered: "",
  status: "idle",
};

export const searchEngineSlice = createSlice({
  name: "searchEngine",
  initialState,
  reducers: {
    updateStock: (state, action) => {
      state.selectedStock = action.payload;
    },
    updateWordEntered: (state, action) => {
      state.wordEntered = action.payload;
    },
  },
});

export const { updateStock, updateWordEntered } = searchEngineSlice.actions;

export default searchEngineSlice.reducer;
