import React from "react";
import Card from "../Components/Card";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

function About() {
  return (
    <div className="About bg-back">
      <Header />
      <div className="grid gap-8 md:m-24">
        <Card style="text-primary text-xl">
        <b>Stock Perception</b> is an app that makes checking the sentiment on a particular stock a breeze.

        </Card>
      </div>

      <Footer />
    </div>
  );
}

export default About;
