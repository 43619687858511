import React from "react";

const Hello = () => {
  return (
    <div className="MainFont text-primary">
      <p className="text-6xl">Stock Perception</p>
      <p className="pt-4">Look for any stock to check it`s sentiment</p>
    </div>
  );
};

export default Hello;
