import React from "react";
import { useAppSelector } from "../redux/redux-hooks";

import Stock from "./Stock";
import SearchInput from "./SearchInput";
import Hello from "./Hello";

function SearchEngine( { data } ) {
  const selectedStock = useAppSelector((state) => state.searchEngine.selectedStock);
  return (
    <div className="SearchBar grid grid-cols-1 bg-back md:p-24 p-4 pt-16">
      <Hello />
      <SearchInput stockList={data} />
      <div className="mt-48">
        <Stock searchedStock={selectedStock} />
      </div>
    </div>
  );
}

export default SearchEngine;
