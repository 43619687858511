import React, {useState} from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SearchEngine from "../Components/SearchEngine";
import { MockDataStocks } from "../MockData";
import { getStockList } from "../Services/StockListService";

function Main() {
  const [stockList, setstockList] = useState([])
  React.useEffect(() => {
    async function stockList() {
      const {data} = await getStockList()
      setstockList(data)
    }
    stockList();
  }, [stockList]);

  return  (
    <div className="Main">
      <Header />
      <SearchEngine data={MockDataStocks} />
      <Footer />
    </div>
  );
}

export default Main;
