import React, { useState } from "react";
import { getAppVersion } from "../Services/VersionService";

function Footer() {
  const [AppVersion, setAppVersion] = useState([]);
  const contentCSS = "hover:text-secondary";
  const currentYear = new Date().getFullYear();

  React.useEffect(() => {
    async function appVersion() {
      let { version } = await getAppVersion();
      setAppVersion(`Version ${version}` || "App Version");
    }
    appVersion();
  }, []);

  return (
    <div className="Footer bg-secback text-primary grid place-content-center grid-flow-col gap-4 h-24 drop-shadow-2xl">
      <div className={contentCSS}>© Stockperception {currentYear}</div>
      <div>{AppVersion}</div>
    </div>
  );
}

export default Footer;
