import React from "react"

const Description = ({stock}) => {
  return (
    <div >
        <h1 className="MainFont text-3xl">{stock.name}</h1>
        <p><b>
          {`${stock.ticker} | `}
          </b> 
          { `Industry sector : ${stock.sector} `}</p>
    </div>
  )
}

export default Description
