import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import React from "react";

import Main from "./Pages/Main";
import API from "./Pages/API";
import About from "./Pages/About";
import PageNotFound from "./Pages/PageNotFound";

function App() {
  return (
    <Router className="App">
      <Switch>
        <Route path="/" element={<Main />} />
        <Route path="/api" element={<API />} />
        <Route path="/About" element={<About />} />
        <Route path="*" element={<PageNotFound />} />
      </Switch>
    </Router>
  );
}

export default App;
