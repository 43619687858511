import React from "react";
import { ResponsiveLineCanvas } from "@nivo/line";
import { colorThemes } from "../../Theme";

function Graph({ data, name }) {
  if (data.data.length < 2) {
    return (
      <div>
        <h1 className="font-bold text-xl">{`Sentiment graph for ${name}`}</h1>No graph for this sentiment {">_<"}
      </div>
    );
  }
  return (
    <div className="h-64 w-auto">
      <h1 className="font-bold text-xl">{`Sentiment graph for ${name}`}</h1>
      <ResponsiveLineCanvas
        margin={{ top: 50, right: 40, bottom: 70, left: 60 }}
        data={[data]}
        curve="basis"
        colors={colorThemes.Original.primary}
        xScale={{ type: "point", reverse: true }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
        }}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Date",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Sentiment",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        useMesh={true}
      />
    </div>
  );
}

export default Graph;
