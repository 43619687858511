import { faker } from "@faker-js/faker";

const MockStocks = [
  {
    id: 0,
    name: "Criuleni International",
    ticker: "CRIN",
    sentiment:19,
    sector: "Consumer Services",
    news: [{
      header:"My 2nd Rock Bottom: Deep In Debt and Deeply Depressed",
      link:"/aa"
    },
    {
      header:"My 1st Rock Bottom: Dead at the Scene",
      link:"/bb"
    }],
    sentiment_graph:{
      id: "Average sentiment",
      color:faker.color.rgb(),
      data:[{x:1,y:12}]
    },
    last_updated: "11/12/2021",
  },
  {
    id: 1,
    name: "AMD",
    ticker: "AMD",
    sector: "Miscellaneous",
    news: [],
    sentiment_graph:{
      id: "Average sentiment",
      color:faker.color.rgb(),
      data:[{x:1,y:12}]
    },
    last_updated: "8/1/2021",
    sentiment:19
  }
];


const STOCKS = [];

function createNews(){
  let newsList = []
  let length = faker.datatype.number(5)
  for(let i = 0; i<length ;i++){
    newsList.push(
      {
        header: faker.commerce.productDescription(),
        link: faker.internet.url()
      }
    )
  }
  return newsList
}
function createSentimentGraph(){
  let sentimentList =  {
    id: "Average sentiment",
    color:faker.color.rgb()
  }
  let temp = []
  let length = faker.datatype.number(15)
  for(let i = 0; i<length ;i++){
    temp.push({
      x:i,
      y:faker.datatype.number(100)
    }
    )
  }
  sentimentList.data = temp
  return sentimentList
}

export function createRandomStock(){
  let updateDate = `${faker.date.past()}`
  return {
    id: faker.datatype.uuid(),
    name: faker.company.companyName(),
    ticker: faker.lorem.word(),
    sentiment: faker.datatype.number(100),
    sector: faker.company.bsNoun(),
    news: createNews(),
    sentiment_graph: createSentimentGraph(),
    last_updated: updateDate,
  };
}

Array.from({ length: 10 }).forEach(() => {
  STOCKS.push(createRandomStock());
});


export const MockDataStocks = [...MockStocks, ...STOCKS] 
