export const colorThemes = {
    Original :{
        name:"Original look",

        primary:"#0e9e2c",
        secondary:"#1bc23e",
        back:"#f5f5f5",
        secback:"#ffffff",
        textcolor:"#1a1a1a"
    },
    Dark:{
        name:"Fresh yellow",

        primary:"#0e9e2c",
        secondary:"#1bc23e",
        back:"#f5f5f5",
        secback:"#ffffff",
        textcolor:"#1a1a1a"
    }
}
