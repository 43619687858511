
import React from "react"

const logo = ({size}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.32 67.22" className={size}>
        <g id="Layer_1" data-name="Layer 1">
            <path className="cls-1" d="M140,228.72l30.36.19c10.64.06,19.22,8,19.16,17.74l-.23,36.89" transform="translate(-120.24 -216.32)"/>
            <path className="cls-2" d="M169.75,271.14,139.39,271c-10.63-.07-19.21-8-19.15-17.75l.22-36.89" transform="translate(-120.24 -216.32)"/>
        </g>
</svg>
  )
}

export default logo


