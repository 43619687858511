import React from "react"

const PageNotFound = () => {
  return (
    <div className="flex justify-center md:m-72 mt-48 bg-back p-8">
    <div>
        <div className="text-center text-2xl text-bold">Page not found {">_<"}</div> 
        <a className="hover:text-primary" href="/">Go back to main page {">"}</a>
    </div>
    </div>
  )
}

export default PageNotFound
