import React, { useState } from "react";

import { updateWordEntered, updateStock } from "../redux/searchEngineSlice";
import { useAppDispatch, useAppSelector } from "../redux/redux-hooks";

const SearchInput = ({ stockList }) => {
  const [filteredData, setFilteredData] = useState([]);
  const WordEntered = useAppSelector((state) => state.searchEngine.wordEntered);
  let dispatch = useAppDispatch();
  const handleFilter = (event) => {
    let word = event.target.value.toLowerCase();
    dispatch(updateWordEntered(word));
    let newFilter = stockList.filter((stock) => {
      
      return stock.name.toLowerCase().includes(word) || stock.ticker.toLowerCase().includes(word);
    });
    word === "" ? setFilteredData([]) : setFilteredData(newFilter);
  };

  const handleNewStock = (stock) => {
    dispatch(updateStock(stock));
    dispatch(updateWordEntered(stock.name));
    setFilteredData([]);
  };

  return (
    <div className="mt-8">
      <div className="searchInput ">
        <input
          className="outline-0 w-96 p-2 rounded"
          id="searchInput"
          value={WordEntered}
          onChange={handleFilter}
          placeholder={stockList.length == 0 ?"No stocks retrieved from database":"Enter company/ticker"}
        />
      </div>
      <div className="dataResults w-96 h-36 mt-2 absolute overscroll-contain overflow-x-hidden">
        {filteredData.length !== 0 && (
          <div className="dataList bg-secback">
            {filteredData.slice(0, 10).map((stock) => {
              return (
                <div
                  onClick={() => handleNewStock(stock)}
                  key={stock.id + "stock"}
                  className="searchItem p-2 hover:text-secondary cursor-pointer"
                >
                  {stock.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
