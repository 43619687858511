import React from "react";
import Logo from "../svg/logo";

function Header() {
  const link = "hover:text-secondary text-lg font-bold p-8";

  return (
    <div className="Header h-24 text-primary bg-back">
      <div className="md:ml-24 ml-4 flex items-center">
        <div className="bg-primary rounded-b-3xl drop-shadow-xl">
          <a href="/">
            <div className="Logo p-4 bg-primary rounded-b-3xl hover:translate-y-4 duration-150" alt="logo">
              <Logo size="w-16" />
            </div>
          </a>
        </div>
        <div>
          <a href="/" className={link}>Home</a>
          <a href="/about/" className={link}>About</a>
          <a href="/api/" className={link}>API</a>
        </div>
      </div>
    </div>
  );
}

export default Header;
