import React from "react"

const Card = ({style, children}) => {
  return (
    <div className={"bg-secback w-96 p-4 rounded "+style}>
        {children}
    </div>
  )
}

export default Card
