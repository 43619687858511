import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

function FAQ() {
  return (
    <div className="API bg-back">
      <Header />
      <div className="m-24 p-2 MainFont text-4xl text-primary">
        Oops, <br/>
        Under construction
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
