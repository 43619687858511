import React, { useState } from "react";
import { getStock } from "../Services/StockService";

import Description from "./TickerComponents/Description";
import Graph from "./TickerComponents/Graph";
import News from "./TickerComponents/News";
import Sentiment from "./TickerComponents/Sentiment";

const mainBoxCss = "Stock bg-secback rounded grid grid-flow-row p-4 gap-4";
const contentBoxCss = "bg-secback rounded drop-shadow-lg p-4";

function Stock({ searchedStock }) {
  const [stock, setStock] = useState({});

  React.useEffect(() => {
    if (searchedStock) {
      getStock(searchedStock.ticker).then(({ data }) => setStock(data));
    }
  }, [stock]);

  if (!searchedStock) {
    return <div className={mainBoxCss}>Enter the name</div>;
  }

  return (
    <div className={mainBoxCss}>
      <div className={contentBoxCss}>
        <Description stock={searchedStock} />
      </div>

      <div className="grid md:grid-flow-col gap-4">
        <div className={contentBoxCss}>
          <Sentiment stock={searchedStock} />
        </div>
        <div className={contentBoxCss + " min-w-0"}>
          <Graph data={searchedStock.sentiment_graph} name={searchedStock.name} />
        </div>
      </div>

      <div className={contentBoxCss}>
        <News stock={searchedStock} />
      </div>

      <div className="text-primary text-right">{`Last updated on ${searchedStock.last_updated}`}</div>
    </div>
  );
}

export default Stock;
